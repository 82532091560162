import React from "react"
import setupTags from "../utils/setupTags"
import { Link } from "gatsby"
import slugify from "slugify"
import categories from "../assets/images/categories.png"
export const TagList = ({ articles }) => {
  const newTags = setupTags(articles)
  console.log(newTags)
  return (
    <div className="tag-container">
      <img className="categories" src={categories} />
      <div className="tags-list">
        {newTags.map((tag, index) => {
          const [text, value] = tag
          const slug = slugify(text, { lower: true, remove: /'/ })
          return (
            <Link to={`/tags/${slug}`} key={index}>
              {text} ({value})
            </Link>
          )
        })}
      </div>
    </div>
  )
}
export default TagList
