import React from "react"
import Layout from "../components/Layout"
import AllArticles from "../components/AllArticles"
import Seo from "../components/Seo"
const boutique = () => {
  return (
    <Layout>
      <Seo title="Shop" />
      <main className="page">
        <AllArticles />
      </main>
    </Layout>
  )
}

export default boutique
