import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import TagList from "./TagList"
import ArticlesList from "./ArticlesList"

const query = graphql`
  {
    allContentfulProduct(sort: { order: ASC, fields: name }) {
      nodes {
        id
        name
        tags {
          tags
        }
        featured
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: TRACED_SVG
            width: 500
            height: 500
            cornerRadius: 60
            formats: WEBP

            cropFocus: CENTER
          )
        }
      }
    }
  }
`
const AllArticles = () => {
  const data = useStaticQuery(query)
  const articles = data.allContentfulProduct.nodes
  console.log(articles)
  return (
    <section className="recipes-container">
      <TagList articles={articles} />
      <ArticlesList articles={articles} />
    </section>
  )
}
export default AllArticles
